import styled from 'styled-components'
import media from "styled-media-query";

export const LayoutWrapper = styled.section`
  display: flex;

  ${media.lessThan("medium")`
    flex-direction: column;
  `}
`

export const LayoutMain = styled.main`
  min-height: 100vh;
  width: 100%;

  ${media.lessThan("medium")`
     display: flex;
     flex-direction: column;
     padding: 0;
     width: auto;
  `}
  
`